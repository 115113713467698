import React, { useContext, useEffect } from 'react';
import { Speakers, Agenda, ProtectedRoute, SEO, VideoGallery, EventHeader } from 'components';
import { graphql } from 'gatsby';
import { LocalContext, FirebaseContext } from 'context';

function LivestreamTemplate({ data }) {
  const { frontmatter: event } = data.markdownRemark;
  const { setSelectedEvent } = useContext(LocalContext);
  const { loading, firebase, user } = useContext(FirebaseContext);

  useEffect(() => {
    if (event.eid) {
      setSelectedEvent({
        eid: event.eid,
        name: event.name,
        colors: event.colors,
        slug: event.slug
      });
    }
    return () => {
      if (!loading && firebase) {
        firebase.updateUserPresence({
          uid: firebase.auth.currentUser?.uid,
          eid: ''
        });
        setSelectedEvent(null);
      }
    };
  }, [loading, firebase, event.eid]);

  useEffect(() => {
    let unsubscribeToRTDBServer;

    // Sometimes the user object can initialise briefly with the user data from Firebase Auth but
    // not yet the user data from Firestore, so to prevent any errors here we check for the uid
    // property on the user object, to make sure it has the Firestore data. If we don't do this then
    // the code may try to update 'presence' on a document that doesn't exist yet, resulting in an
    // error.
    if (!loading && firebase && user?.uid) {
      const { uid } = user;
      unsubscribeToRTDBServer = firebase.subscribeToRTDBServer({
        snapshot: (snapshot) => {
          if (snapshot.val()) {
            return firebase.updateUserPresence({ uid, eid: event?.eid ?? '' });
          }
          return firebase.updateUserPresence({ uid, eid: '' });
        }
      });
    }
    return () => {
      if (unsubscribeToRTDBServer) {
        unsubscribeToRTDBServer();
      }
    };
    // We're using user?.uid in the dependency array here - instead of just user - to avoid getting
    // into an infinite loop each time presence is updated on the user object.
  }, [loading, firebase, user?.uid, event?.eid]);

  return (
    <ProtectedRoute>
      <SEO
        pageSpecificTitle="Livestream"
        pageSpecificDescription={event.description}
        pageSpecificThumbnail={event.seoThumbnail}
        pageSpecificThumbnailAlt={event.name}
      />
      <EventHeader event={event} />
      <Agenda agenda={event.agenda} colors={event.colors} />
      <Speakers speakers={event.speakers} colors={event.colors} />
      <VideoGallery colors={event.colors} />
    </ProtectedRoute>
  );
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        eid
        name
        slug
        status
        slug
        status
        colors {
          primary
          secondary
          tertiary
        }
        isChatEnabled
        isQAndAEnabled
        isPollsEnabled
        isParticipantsEnabled
        name
        title
        subtitle
        seoThumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        description
        date
        startTime
        endTime
        streamUrl
        eventHighlights {
          id
          url
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          title
          description
        }
        bannerVideo
        speakers {
          id
          name
          position
          socials {
            facebook
            twitter
            instagram
            linkedIn
          }
          title
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        agenda {
          day
          title
          slots {
            orderNumber
            timeSlot
            title
            speaker
          }
        }
      }
    }
  }
`;

export default LivestreamTemplate;
